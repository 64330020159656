import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Floating } from "./Floating";
import { calculateOrderAmount, Constants, sendOrder, setInLocalStorage, useToken } from "./Hooks";
import { Loader } from "./Loader";
import { TopBar } from "./TopBar";

export function CartObject(){

    const [enterprise, setEnterprise]           = useState(null);
    const [displayLoader, setDisplayLoader]     = useState(false);
    const [choosedDep, setChoosedDep]           = useState([]);
    const [defaultDepartment, setDefault]       = useState( true );
    const [exit, setExit]                       = useState(null);
    const [control, setControl ]                = useState( 1 )
    const params                                = useParams();
    const navigate                              = useNavigate();
    const token                                 = useToken();
    
    useEffect(() => {
        if(!token){
            navigate("/");
            return;
        }

        const _enterprise = JSON.parse(localStorage.getItem('enterprise'));
        setEnterprise(_enterprise);

        if(params && params.exit){
            setExit(params.exit == "1" ? -1 : parseInt(params.exit));
        }

    }, []);

    return (
        <div className="cart">
            <TopBar displayArrowBack></TopBar>
            {
                displayLoader ? 
                    <Loader/>
                :
                    <></>
            }
            <div className="cart-page">
                {
                    exit && Constants.Cart && Constants.Cart.length > 0 ?
                        <Floating extend click={ async () => {
                            if( control <= 0){ return }
                            setDisplayLoader(true);
                            Constants.OpenedParkedOrders = false;  
                            const isSended = await sendOrder(exit, choosedDep);
                            setDisplayLoader(false);
                            if( isSended ){
                                setInLocalStorage();
                                navigate("/tables");
                            }
                            setControl( 0 )
                        }}>
                            
                                {
                                    control <= 0 ? <div style={{ fontSize:'13px', fontWeight:'bold', wordWrap:'break-word', width:'100%', }}>Seleziona stampante</div> :<div> INVIA&nbsp;&nbsp;<i class="fas fa-paper-plane"></i></div>
                                }
                                
                            
                        </Floating>
                    :
                        <></>
                }
                <div className="receipt">
                    {
                        exit && Constants.Cart && Constants.Cart.length > 0 ?
                            <>
                                <div style={{textAlign: "center", width: "100%"}}>
                                    {
                                        enterprise && enterprise.business_name ? 
                                            <>
                                                <div style={{fontWeight: "bold"}}>
                                                    <div>{enterprise.business_name}</div>
                                                    <div>DOCUMENTO GESTIONALE</div>
                                                    <div>-- TAVOLO: {Constants.currentTable.description} --</div>
                                                </div>
                                                <div style={{width: "100%", textAlign: "center", whiteSpace: "nowrap", overflow: "hidden"}}>--------------------------------</div>
                                            </>
                                        :
                                            <></>
                                    }
                                </div>
                                <div className="header">
                                    <div style={{textAlign: "left"}}>DESCRIZIONE</div>
                                    <div style={{textAlign: "right"}}>PREZZO</div>
                                </div>
                            </>
                        :
                            <>
                                <div style={{fontWeight: "bold", textAlign: "center"}}>
                                    <div>NESSUN ORDINE</div>
                                </div>
                            </>
                    }
                    <div style={{display: "flex", flexDirection: "column", gap: "5px"}}>
                        {
                            exit && Constants.Cart && Constants.Cart.length > 0 ?
                                Constants.Cart.filter(c => c.exit == exit).map((line, index) => 
                                    <>
                                        <div className="body">
                                            <div>
                                                {
                                                    line.note ? `${ line.description }     (${line.note})` : line.description
                                                    
                                                }
                                            </div>
                                            <div>
                                                {
                                                    line.quantity.toFixed(2) + "x " + line.price.toFixed(2) + "€"
                                                }
                                            </div>

                                        </div>
                                        {
                                            line.variants && line.variants.length > 0 ?
                                                <>
                                                    <div style={{fontSize: "12px", marginLeft: "10px", fontStyle: "italic"}}>
                                                        +&nbsp;
                                                        {
                                                            line.variants.map((variant, index) => 
                                                                <span>{variant.description}{index + 1 < line.variants.length ? ", " : ""}</span>
                                                            )
                                                        }
                                                    </div>
                                                </>
                                            :
                                                <></>
                                        }
                                    </>
                                )
                            :
                                <></>
                        }
                    </div>
                    {
                        Constants.Cart && Constants.Cart.length > 0 ? 
                            <>
                                <div style={{width: "100%", textAlign: "center", whiteSpace: "nowrap", overflow: "hidden"}}>--------------------------------</div>
                                <div className="footer">
                                    <div>TOTALE</div>
                                    <div>{calculateOrderAmount().toFixed(2)} €</div>
                                </div>
                            </>
                        :
                            <></>
                    }
                </div>
                
                {
                    // Reparti di produzione
                }
                <div className="department-production">
                    <h5 style={{fontWeight: "bold"}}>REPARTI</h5>
                    <div style={{width: "100%", textAlign: "center", whiteSpace: "nowrap", overflow: "hidden"}}>--------------------------------</div>
                    <div className="line-department" key={`dep_${'default'}`}>
                                <input type="checkbox" style={{width: "30px"}} onChange={(e) => {
                                    if( e.target.checked ){
                                        setControl( 1 )
                                        setChoosedDep( [] );
                                        setDefault( true )
                                    }else{
                                        setDefault( false )
                                        setControl( 0 )
                                    }
                                    

                                }} checked={defaultDepartment}></input>
                                <div>Predefinite</div>
                            </div>
                    {
                        
                        
                        (Constants.ProducionDepartments ?? []).map((department, index) => 
                            <div className="line-department" key={`dep_${index}`}>
                                <input type="checkbox" style={{width: "30px"}} onChange={(e) => {
                                    if(e.target.checked){
                                        if( setDefault ) setControl(0)
                                        setControl( control + 1)
                                        console.log(control)
                                        setDefault( false )
                                        //setControl( 0 )
                                    }else if( !e.target.checked ){
                                        console.log(control)
                                        setControl( control - 1)
                                    }
                                    const copy = JSON.parse(JSON.stringify(choosedDep));
                                    if(e.target.checked){
                                        const indexDep = copy.findIndex(d => d.id == department.id);
                                        if(indexDep == -1){
                                            copy.push(department);
                                        }
                                    }else{
                                        const indexDep = copy.findIndex(d => d.id == department.id);
                                        if(indexDep > -1){
                                            copy.splice(indexDep, 1);
                                        }
                                    }
                                    setChoosedDep(JSON.parse(JSON.stringify(copy)));
                                }} checked={choosedDep.findIndex(d => d.id == department.id) > -1}></input>
                                <div>{department.description}</div>
                            </div>
                        )
                    }
                </div>

                <div style={{height: "100px"}}/>

            </div>
        </div>
    );
}