import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Floating } from "./Floating";
import { Constants, addCover, coperti, generateCopy, setInLocalStorage, useEndpoint, useToken } from "./Hooks";
import { TopBar } from "./TopBar";
import { v4 as uuidv4 } from 'uuid';

export function Categories(){

    const params = useParams();
    const [categories, setCategories] = useState([]);
    const [copyCategories, setCopyCategories] = useState([]);
    const [allCategories, setAllCategories] = useState([]);
    const navigate = useNavigate();
    const endpoint = useEndpoint();
    const token = useToken();

    useEffect(() => {

        fetch(
            `${endpoint}/api/mb/v1/easyappear_connector/get_opened_order_table`,
            {
                method: "GET",
                headers: {
                    'jwt': token
                }
            }
        ).then(async (response) => {
            if(response.status == 200){
                const json = await response.json()
                if(json.Result == "OK"){
                    let table = json.Json.orders;
                    table = table.filter(p => p.table == Constants.currentTable.code )
                    Constants.currentSentOrder = table[0].products
                }
            }
            
        });

        let idParent = params.id == "parent" ? null : parseInt(params.id);

        const _categories = localStorage.getItem('categories');
        if( _categories ){
            const __categories = JSON.parse(_categories);
            setAllCategories(__categories);
            const ___categories = (__categories ?? []).filter(i => (idParent ? (i.category && i.category.id == idParent) : (i.category == null)))
            setCategories(___categories);
            setCopyCategories(generateCopy(___categories));
        }

    }, [ params ]);



    return (
        <div className="category">
            
            <TopBar displayArrowBack={true}/>

            <Floating click={() => {
                if(Constants.Exit > -1){
                    navigate(`/cart/${Constants.Exit}`);
                    return;
                }
                Constants.Exit = -1;
                navigate("/cart/1");
            }}>
                <div>
                    <i class="fas fa-paper-plane"></i>
                </div>
            </Floating>

            <Floating style={{right: "80px"}} click={() => {
                navigate(`/parked_orders/${Constants.currentTable.id.toString()}`);
            }}>
                <i class="fas fa-list-ol"></i>
            </Floating>

            <Floating style={{right: "150px", display: Constants.currentTable.isOpened ? "" : "none" }} click={() => {
                navigate(`/sent/${Constants.currentTable.id.toString()}`);
            }}>
                <i class="fas fa-archive"></i>
            </Floating>

            <div className="category-page">
                <input type={"text"} className="form-control" placeholder="Ricerca categoria.." onChange={(e) => {
                    
                    if(!e.target.value){
                        setCategories(copyCategories);
                        return;
                    }
                    
                    const _categories = copyCategories.filter(c => c.description.trim().toLowerCase().includes(e.target.value.trim().toLocaleLowerCase()));
                    setCategories(_categories);

                }}/>
                {
                    // Coperti
                }
                <button className="btn btn-primary" style={{width: "100%", marginTop: "15px", height: "60px", fontWeight: "bold"}} onClick={async () => {
                    if(await addCover(Constants.currentTable)){
                        setInLocalStorage();
                        navigate("/tables");
                    }
                }}><i class="fas fa-user"></i>&nbsp;&nbsp;AGGIUNGI COPERTO</button>
                <hr style={{color: "white"}}/>
                <div className="flex-categories">
                    {
                        categories && categories.length > 0 ? 
                            categories.map((item, index) => 
                                <div className="category-button" key={`category_${item.id}`} onClick={() => {
                                    
                                        // Controlla se ha almeno un figlio
                                        const hasChildren = allCategories.findIndex(i => i.category && i.category.id.toString() == item.id.toString()) > -1;
                                        if(hasChildren){
                                            navigate(`/categories/${item.id}`);
                                        }else{
                                            navigate(`/catalogues/${item.id}`);
                                        }

                                    }}>
                                        <div  className="category-text" >
                                            {item.description}
                                        </div>
                                </div>
                            )
                        :
                            <></>
                    }
                </div>
            </div>
            <div style={{height: "100px"}}/>
        </div>
    );

}