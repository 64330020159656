import { v4 as uuidv4 } from 'uuid';

var NginxLocation;
const setNginxLocation = (value) => {
    NginxLocation = value;
};

const Constants = {
    currentTable: null,
    currentSentOrder : [],
    Cart: [],
    Exit: -1,
    OpenedParkedOrders: false,
    StartedNow: true,
    CoverCode: "00001_COPERTO",
    date: {day : 4, hours : 12, minutes : 0},
    InitProductionDepartment: false,
    ProducionDepartments: [],
    Image: "files/static/media/logo-white-mallbox-extended.png",
    InTablesView: false
};

const setDate = () =>{
    const date = new Date
    Constants.date.day = date.getDay()
    Constants.date.hours = date.getHours()
    Constants.date.minutes = date.getMinutes()
}

const useToken = () => {
    return localStorage.getItem('token');
};

const useEndpoint = () => {
    return "https://v2.mallbox.it";
};

const useVersion = () => {
    return "0.0.5";
};

const generateCopy = (object) => {
    const obj = JSON.parse(JSON.stringify(object));
    return obj;
};

const cleanLocalStorage = () => {
    localStorage.removeItem('cart');
    localStorage.removeItem('categories');
    localStorage.removeItem('variants');
    localStorage.removeItem('parked_orders');
    localStorage.removeItem('products');
    localStorage.removeItem('token');
    localStorage.removeItem('enterprise');
};

const syncProducts = async (endpoint, token) => {

    const response = await fetch(
        `${endpoint}/api/mb/v1/product/list?page=0&record_for_page=9999999`,
        {
            method: "GET",
            headers: {
                'jwt': token
            }
        }
    );

    if(response && response.status == 200){
        const json = await response.json();
        if(json && json.Result == "OK"){
            const products = json.Json.products;
            if(products && products.length > 0){
                let prds = (products ?? []);
                prds.forEach(p => {
                    if(p.position == null || p.position == undefined || p.position == -1){
                        p.position = 9999999;
                    }
                });
                return prds.filter(p => !p.disabled || p.disabled == 0).sort((a, b) => a.position - b.position);
            }
        }
    }

    return null;
};


const syncCategories = async (endpoint, token) => {

    const response = await fetch(
        `${endpoint}/api/mb/v1/category/get_all_categories?get_images=1`,
        {
            method: "GET",
            headers: {
                'jwt': token
            }
        }
    );

    if(response && response.status == 200){
        const json = await response.json();
        if(json && json.Result == "OK"){
            const categories = json.Json.categories;
            if(categories && categories.length > 0){
                let cats = (categories ?? []);
                cats.forEach(c => {
                    if(c.position == null || c.position == undefined || c.position == -1){
                        c.position = 9999999;
                    }
                })
                return cats.filter(c => !c.disabled || c.disabled == 0).sort((a, b) => a.position - b.position);
            }
  
        }
    }

};

const syncVariants = async (endpoint, token) => {

    const response = await fetch(
        `${endpoint}/api/mb/v1/variant/list?page=0&record_for_page=9999999`,
        {
            method: "GET",
            headers: {
                'jwt': token
            }
        }
    );

    if(response && response.status == 200){
        const json = await response.json();
        if(json && json.Result == "OK"){
            const variants = json.Json.variants;
            if(variants && variants.length > 0){
                return variants;
            }
        }
    }

    return null;
};

const doEllips = (text, maxLength) => {
    if(text.length <= maxLength){
        return text;
    }else{
        return text.substr(0, maxLength) + "..";
    }
};

const generatePrice = (vat, taxable) => {
    const _vat = vat ?? 0.00;
    const tax = (taxable * _vat) / 100;
    const price = taxable + tax;
    return price;
};

const setInLocalStorage = () => {
    localStorage.setItem('cart', JSON.stringify(Constants.Cart));
};

const getProductsInCart = (id) => {
    return Constants.Cart.filter(c => c.id.toString() == id.toString());
};

const calculateOrderAmount = (exit = -1) => {
    
    let amount = 0.00;
    (Constants.Cart ?? [])
    .filter(i => i.exit == -1)
    .forEach(c => {
        const qta = c.quantity;
        amount += (c.price * qta);
    }); 

    return amount;
};

const calculateSentOrderAmount = () => {
    
    let amount = 0.00;
    (Constants.currentSentOrder ?? [])
    .forEach(c => {
        amount += c.price * c.quantity;
    }); 

    return amount;
};

const manageQuantity = (uuid, sign, outside) => {
    const index = Constants.Cart.findIndex(i => i.uuid == uuid);
    if(index > - 1){
        if(sign == "+"){
            Constants.Cart[index].quantity = Constants.Cart[index].quantity + 1;
        }else{
            if(Constants.Cart[index].quantity > 1){
                Constants.Cart[index].quantity = Constants.Cart[index].quantity - 1;
            }else{
                Constants.Cart.splice(index, 1);
            }
        }
    }
    setInLocalStorage();
    if(outside){
        outside();
    }
};

const getVariantsInCart = (uuid) => {

    const index = Constants.Cart.findIndex(c => c.uuid == uuid);
    if(index > -1){
        const variants = (Constants.Cart[index].variants ?? []);
        if(variants && variants.length > 0){
            let obj = {
                description: "",
                amount: 0.00
            };
            variants.forEach(v => {
                obj.description += v.description + ", ";
                obj.amount += v.price ?? 0.00;
            });
            obj.description = obj.description.substring(0, obj.description.length - 2);
            return obj;
        }
    }

    return null;
};

const currentDate = () => {
    const date = new Date();
    return (
        date.getFullYear().toString()                       + "-" + 
        (date.getMonth() + 1).toString().padStart(2, '0')   + "-" + 
        date.getDate().toString().padStart(2, '0')          + " " + 
        date.getHours().toString().padStart(2, '0')         + ":" + 
        date.getMinutes().toString().padStart(2, '0')       + ":" + 
        date.getSeconds().toString().padStart(2, '0')
    );
};

const clean = () => {
    Constants.Cart = [];
    Constants.Exit = -1;
    setInLocalStorage();
    Constants.currentTable = null;
};

const sendOrder = async (exit = -1, department_production = null) => {

    const now = currentDate();

    const prds = [];
    (Constants.Cart ?? [])
    .filter(i => i.exit == exit)
    .forEach(cart => {
        prds.push({
            "id": null,
            "id_app": null,
            "id_company": null,
            "id_order": null,
            "id_product": cart.variants,  //invio l'uuid del prodotto per agganciare le variabili
            "id_product_parent": null,
            "reference_parent": null,
            "reference_row": null,
            "title": cart.description,
            "reference": "51",
            "barcode": null,
            "selling_type": "piece",
            "selling_label": "",
            "weight": "0.00",
            "quantity": cart.quantity.toFixed(2),
            "discount": "0.00",
            "original_price": cart.price.toFixed(2),
            "price": cart.price.toFixed(2),
            "total": (cart.price * cart.quantity).toFixed(2),
            "note": cart.note,
            "row_data": null,
            "position": null,
            "created_at": now,
            "updated_at": now,
            "components": [],
            "messages": [],
            "idMallbox": cart.id
        });
    });

    const jsonOrder = {
        "reference": uuidv4().toLowerCase().split("-").join(""),
        "merchant_order_id": null,
        "invoice_name": "CAMERIERE",
        "invoice_surname": "CAMERIERE",
        "invoice_address": "-",
        "invoice_zip_code": "00000",
        "invoice_city": "CAMERIERE",
        "invoice_province": "CAMERIERE",
        "invoice_state": "CAMERIERE",
        "invoice_phone": "00000000",
        "invoice_mobile": "00000000",
        "invoice_fax": null,
        "invoice_email": "CAMERIERE",
        "delivery_name": "CAMERIERE",
        "delivery_surname": "CAMERIERE",
        "delivery_address": "CAMERIERE",
        "delivery_zip_code": "00000",
        "delivery_city": "CAMERIERE",
        "delivery_province": "CAMERIERE",
        "delivery_state": "IT",
        "delivery_phone": "00000000",
        "delivery_mobile": "00000000",
        "delivery_fax": null,
        "delivery_email": "V",
        "fiscal_code": null,
        "lottery_code": null,
        "number_persons": null,
        "total_cover_charge": "0.00",
        "total_service_charge": "0.00",
        "device_reference": "",
        "device_name": "",
        "location_name": null,
        "position_token": null,
        "position_reference": Constants.currentTable.code,
        "position_name": Constants.currentTable.description,
        "delivery_time": null,
        "rental_time_value": null,
        "rental_time_type": null,
        "rental_minutes": null,
        "rental_start_at": null,
        "rental_end_at": null,
        "order_type": "shop",
        "partial_payment": "0.00",
        "partial_payment_other_label": null,
        "total_discount": "0.00",
        "total_shipping": "0.00",
        "total_products": "31.80",
        "total_paid": "31.80",
        "shipping_type": "self_order",
        "payment_type": "cash_desk",
        "payment_other_label": null,
        "note": "",
        "form_data": null,
        "ticket_form_data": null,
        "previous_state": null,
        "state": "new",
        "created_at": now,
        "updated_at": now,
        "products": [...prds],
        "department_production": department_production && department_production.length == 1 ? department_production[0] : null,
        "departments_production": (department_production ?? []).length > 1 ? department_production : []
    };

    // Ottieni token dell'app
    const response = await fetch(
        `https://v2.mallbox.it/api/mb/v1/easyappear_connector/get_token_app`,
        {
            method: "GET",
            headers: {
                'jwt': localStorage.getItem('token')
            }
        }
    );

    if(response.status == 200){
        const json = await response.json();
        if(json.Result == "OK"){
            const token = json.Json.token_app;
            if(token){
                
                const responseOrder = await fetch(
                    `https://v2.mallbox.it/api/mb/v1/easyappear_connector/set_order?token_app=${token}`,
                    {
                        method: "POST",
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(jsonOrder)
                    }
                );

                if(responseOrder.status == 201){
                    const _json = await responseOrder.json();
                    if(_json.Result == "OK"){

                        // Salva per il tavolo l'uscita
                        let parked_orders_str = localStorage.getItem('parked_orders');
                        if(!parked_orders_str){
                            parked_orders_str = "[]";
                        }
                        const parked_orders_json = JSON.parse(parked_orders_str);
                        const index = parked_orders_json.findIndex(i => i.table.toString() == Constants.currentTable.id.toString());

                        // Se è la prima uscita, parcheggia tutte le altre
                        if(exit == -1){
                            const noFirstExit = Constants.Cart.filter(i => i.exit != -1);
                            if(noFirstExit.length > 0){
                                
                                if(index > -1){
                                    parked_orders_json[index].products = [...noFirstExit];
                                }else{
                                    parked_orders_json.push({
                                        table: Constants.currentTable.id.toString(),
                                        products: [...noFirstExit]
                                    });
                                }

                            }
                        }else{

                            // Se l'uscita non è la prima, cancella!                            
                            const filtered = Constants.Cart.filter(i => i.exit == exit);
                            filtered.forEach(f => {
                                const _index = (parked_orders_json[index].products ?? []).findIndex(k => k.uuid == f.uuid);
                                if(_index > -1){
                                    parked_orders_json[index].products.splice(_index, 1);
                                }
                            });


                        }

                        // Salva nel localStorage
                        localStorage.setItem('parked_orders', JSON.stringify(parked_orders_json));

                        // Svuota carrello
                        clean();

                        return true;
                    }
                }

            }
        }
    }

    return false;
};

const addCover = async (table) => {

    let canContinue = false;
    let products = [];
    try{
        products = JSON.parse(localStorage.getItem('products'));
    }catch(_){}

    try{
        const indexCover = products.findIndex(i => i && i.code == Constants.CoverCode);
        if(indexCover == -1){
            alert("Attenzione, manca il coperto nel gestionale! Configurarlo da backoffice con codice identificativo " + Constants.CoverCode);
        }else{
            let numberOfCovers = prompt("Inserire numero di coperti");
            if(!isNaN(numberOfCovers)){
                numberOfCovers = parseInt(numberOfCovers);
                if(numberOfCovers > 0){
                    let product = products[indexCover];
                    const price = generatePrice(product.vat ? product.vat.value : 0.00, product.prices && product.prices.length > 0 ? product.prices[0].taxable : 0.00);
                    const obj = {
                        uuid: uuidv4(),
                        id: product.id,
                        code: product.code,
                        description: product.description,
                        price: price,
                        quantity: numberOfCovers,
                        exit: -1,
                        variants: []
                    };
                    Constants.currentTable = table;
                    Constants.Cart.push(obj);
                    try{
                        canContinue = await sendOrder();
                    }catch(_){}
                    if(!canContinue){
                        clean();
                    }else{
                        table.isOpened = true;
                    }
                }
            }
        }
    }catch(_){}
        
    return canContinue;
};

const updateParkedOrder = () => {

    if(Constants.OpenedParkedOrders){
        const parkedOrders = JSON.parse(localStorage.getItem('parked_orders'));
        const index = parkedOrders.findIndex(t => t.table == Constants.currentTable.id.toString());
        if(index > -1){
            const prds = (parkedOrders[index].products ?? []).filter(p => p.exit.toString() != Constants.Exit.toString());
            Constants.Cart.forEach(p => {
                prds.push(p);
            });
            parkedOrders[index].products = [...prds];
            localStorage.setItem('parked_orders', JSON.stringify(parkedOrders));
        }
    }

};


export {useToken, setDate, calculateSentOrderAmount, useEndpoint, useVersion, generateCopy, syncProducts, doEllips, syncVariants, Constants, generatePrice, setInLocalStorage, getProductsInCart, calculateOrderAmount, manageQuantity, getVariantsInCart, currentDate, sendOrder, clean, syncCategories, NginxLocation, setNginxLocation, cleanLocalStorage, updateParkedOrder, addCover};