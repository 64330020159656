import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { cleanLocalStorage, Constants, generateCopy, syncCategories, syncProducts, syncVariants, useEndpoint, useToken, setCoperti, coperti, generatePrice, sendOrder, clean, addCover, useVersion } from "./Hooks";
import { Loader } from "./Loader";
import { Modal } from "./Modal";
import { TopBar } from "./TopBar";
import { v4 as uuidv4 } from "uuid";
import WebSocket from "./WebSocket";

const Tables = () => {

    const [tables, setTables]                   = useState([]);
    const [copyTables, setCopyTables]           = useState([]);
    const [loading, setLoading]                 = useState(false);
    const [timestamp, setTimestamp]             = useState(new Date().getTime());
    const [init, setInit]                       = useState(false);
    const [displayModal, setDisplayModal]       = useState(false);
    const [exitMessage, setExitMessage]         = useState(false);
    const [isOpenedserch, setIsOpenedSerch]     = useState(false);
    const endpoint                              = useEndpoint();
    const token                                 = useToken();
    const navigate                              = useNavigate();
    const version                               = useVersion();

    const getTableStatus = async (tablesData, withLoading = false) => {

        fetch(
            `https://v2.mallbox.it/api/mb/v1/easyappear_connector/get_opened_order_table`,
            {
                method: "GET",
                headers: {
                    'jwt': token
                }
            }
        ).then( async (response) => {

            if(response.status == 200){
                const json = await response.json();
                if(json.Result == "OK"){
                    setTimeout(() => {
                        
                        json.Json.orders.forEach(order => {
                            const index = tablesData.findIndex(t => t.code == order.table);
                            if(index > -1 && tablesData[index]){
                                tablesData[index].isOpened = true;
                            }
                        });

                        // Ottieni tutti quelli aperti
                        tablesData.filter(t => t.isOpened).forEach(t => {
                            const index = (json.Json.orders ?? []).findIndex(j => j.table == t.code);
                            if(index == -1){
                                t.isOpened = false;
                            }
                        });

                        const copy = generateCopy(tablesData);
                        const _copy = generateCopy(copy);

                        setTables(copy);
                        setCopyTables(_copy);

                    }, 500);
                    if(withLoading){
                        setLoading(false);
                    }
                }
            }

        });

    };


    useEffect(() => {    

        if(tables && tables.length > 0 && init){

            // Connessione websocket
            WebSocket.delegateEvent = () => getTableStatus(tables, true);
            if(!WebSocket.hasInit){
                setLoading(true);
                WebSocket.initWs();
            }

            // Ottieni in maniera asincrona lo stato del tavolo
            getTableStatus(tables, true);
            setTimeout(() => {setIsOpenedSerch(true)}, 600) ;
        }

        // Ottieni reparti di produzione
        if(!Constants.InitProductionDepartment){
            Constants.InitProductionDepartment = true;
            fetch(
                `${endpoint}/api/mb/v1/production_department/list?page=0&record_for_page=999`,
                {
                    method: "GET",
                    headers: {
                        'jwt': token
                    }
                }
            ).then(async (response) => {

                if(response.status == 200){
                    const json = await response.json();
                    if(json.Result == "OK"){
                        Constants.ProducionDepartments = json.Json.production_departments ?? [];
                    }
                }

            });
        }
        
    }, [init]);


    useEffect(() => {
        
        Constants.InTablesView = true;

        if(!token){
            navigate("/");
            return;
        }

        if(Constants.OpenedParkedOrders){
            localStorage.removeItem('cart');
            Constants.OpenedParkedOrders = false;
            Constants.Exit = -1;
        }

        // Setta carrello come variabile globale
        const _cart = localStorage.getItem('cart');
        if(_cart){
            Constants.Cart = JSON.parse(_cart);
        }else{
            Constants.Cart = [];
        }

        setLoading(true);

        fetch(
            `${endpoint}/api/mb/v1/rest_table/list?page=0&record_for_page=999999`,
            {
                method: "GET",
                headers: {
                    'jwt': token
                }
            }
        ).then(async (response) => {
            
            if(response.status == 401){
                localStorage.removeItem('token');
                window.location.href = "/";
                return;
            }

            if(response && response.status == 200){
                const json = await response.json();
                if(json && json.Result == "OK"){
                    (json.Json.rest_tables ?? []).forEach(t => t.isOpened = false);
                    setTables(json.Json.rest_tables);
                    setCopyTables( generateCopy(json.Json.rest_tables) );
                }
            }

            if(!localStorage.getItem('products')){
                const products = await syncProducts(endpoint, token);
                if(products && products.length > 0){
                    const prds = JSON.stringify(products);
                    localStorage.setItem('products', prds);
                }
            }

            if(!localStorage.getItem('categories')){
                const categories = await syncCategories(endpoint, token);
                if(categories && categories.length > 0){
                    const cats = JSON.stringify(categories);
                    localStorage.setItem('categories', cats);
                }
            }

            if(!localStorage.getItem('variants')){
                const variants = await syncVariants(endpoint, token);
                if(variants && variants.length > 0){
                    const vars = JSON.stringify(variants);
                    localStorage.setItem('variants', vars);
                }
            }

            // init
            setInit(true);

        });

        return (() => {
            Constants.InTablesView = false;
        });

    }, []);

    return (
        <div className="tables-container">
            <TopBar></TopBar>
            <Modal message={"Attenzione"} display={exitMessage} close={() => setExitMessage(false)}>
                <p>Sei sicuro di voler uscire?</p>
                <div style={{display: "flex", justifyContent: "end", gap: "10px"}}>
                    <button className="btn btn-primary" style={{height: "40px"}} onClick={() => setExitMessage(false)}>No</button>
                    <button className="btn btn-danger" style={{height: "40px"}} onClick={() => {
                        cleanLocalStorage();
                        window.location.href = "/";
                    }}>Si</button>
                </div>
            </Modal>

            <div className="tables-page">
                <div style={{textAlign:"left", fontSize: "13px"}}>v.{version}</div>
                <div style={{display: "flex", justifyContent: "space-between"}}>
                    <p>Lista tavoli</p>
                    <div style={{display: "flex", gap: "10px"}}>
                        <button className="btn btn-primary" style={{height: "40px"}} onClick={async () => {
                            setLoading(true);
                            const categories = await syncCategories(endpoint, token);
                            const products = await syncProducts(endpoint, token);
                            const variants = await syncVariants(endpoint, token);
                            
                            setLoading(false);
                            if(categories && categories.length > 0){
                                const prds = JSON.stringify(categories);
                                localStorage.setItem('categories', prds);
                            }
                            if(products && products.length > 0){
                                const prds = JSON.stringify(products);
                                localStorage.setItem('products', prds);
                            }
                            if(variants && variants.length > 0){
                                const vars = JSON.stringify(variants);
                                localStorage.setItem('variants', vars);
                            }
                        }}>Sync</button>
                        <button className="btn btn-danger" style={{height: "40px"}} onClick={() => {
                            setExitMessage(true);
                        }}>Esci</button>
                    </div>
                </div>
                <input type={"text"} className="form-control" style={{marginTop: "10px"}} placeholder="Ricerca tavolo.." onChange={(e) => {
                    if(e.target.value.trim().length == 0){
                        setTables(copyTables);
                        return;
                    }
                    const _tables = copyTables.filter(table => table.description.toLowerCase().includes(e.target.value.toLocaleLowerCase()));
                    setTables(_tables);
                }}/>
                <div style={{display: "grid", gridTemplateColumns: "auto auto auto", gap: "15px", marginTop: "10px"}}>
                    {
                        isOpenedserch && tables && tables.length > 0 ? 
                            tables.map((table, index) => 
                                <div className="table-object" style={{background: table.isOpened ? "red" : "var(--secondary)" }} key={`table_object_${table.id.toString()}_${table.isOpened}`} onClick={async () => {

                                    let canContinue = false;
                                    if(!table.isOpened){
                                        canContinue = await addCover(table);
                                    }else{
                                        canContinue = true;
                                    }

                                    if(canContinue){

                                        setLoading(true);

                                        // Ottieni disponibilità
                                        try{

                                            const prds = JSON.parse(localStorage.getItem('products')); // Azzera disponibilità
                                            prds.forEach(p => {
                                                p.stop_sell = 0;
                                            });

                                            const response = 
                                                await fetch(
                                                    `${endpoint}/api/mb/v1/product/get_stopped_sell`,
                                                    {
                                                        headers: {
                                                            'jwt': token
                                                        }
                                                    }
                                                );
                                            if(response.status == 200){
                                                const json = await response.json();
                                                if(json.Result == "OK"){
                                                    
                                                    json.Json.forEach(p => {
                                                        const index = prds.findIndex(pr => pr.id == p.id);
                                                        if(index > -1){
                                                            prds[index].stop_sell = 1;
                                                        }
                                                    });
                                                }
                                            }

                                            localStorage.setItem('products', JSON.stringify(prds));

                                        }catch(_){

                                        } finally {
                                            setLoading(false);
                                        }

                                        Constants.currentTable = table;
                                        navigate('/categories/parent');
                                    }

                                }}>
                                    {table.description}
                                </div>
                            )
                        :
                            <></>
                    }
                </div>
            </div>
            {
                loading ? 
                    <Loader/>
                :
                    <></>
            }
        </div>
    );
};

export {
    Tables
}